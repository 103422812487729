import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { Fragment } from 'react';
import { configModel, menuLinksSelector } from '@/entities/Config';
import { Link } from '@/sharedUI';

export interface IBurgerMenuProps {
  isDark: boolean;
}

const BurgerMenu = ({ isDark }: IBurgerMenuProps) => {
  const { isOpen, onToggle } = useDisclosure();
  const menuLinks = configModel.useStore(menuLinksSelector);

  return (
    <Menu
      closeOnSelect={true}
      isOpen={isOpen}
    >
      <MenuButton
        aria-label='Burger-button'
        as={IconButton}
        color={isDark ? 'typography.pure' : 'typography.dark'}
        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
        variant='unstyled'
        onClick={() => onToggle()}
      />
      <MenuList
        borderRadius='none'
        color='typography.dark'
        display='flex'
        flexDirection='column'
        fontWeight='semibold'
        p='md'
        textTransform='uppercase'
        transition='0.2s'
        w='100vw'
      >
        {menuLinks?.map((item: { name: string; link: string }, index: number) => (
          <Fragment key={item.link}>
            <MenuItem
              as={Link}
              href={`/#${item.link}`}
            >
              {item.name}
            </MenuItem>
            {index !== menuLinks.length - 1 && <MenuDivider />}
          </Fragment>
        ))}
      </MenuList>
    </Menu>
  );
};

export default BurgerMenu;
