import { ContainerProps } from '@chakra-ui/react';
import { ComponentProps, FC, ReactElement } from 'react';
import { configModel } from '@/entities/Config';
import { Layout } from '@/sharedUI';

interface IHeaderLayoutProps {
  children: ReactElement;
  styles?: ContainerProps | ComponentProps<typeof Layout.Container>;
}

const HeaderLayout: FC<IHeaderLayoutProps> = ({ children, styles }) => {
  return (
    <Layout.Container
      as='header'
      data-testid='header'
      position='sticky'
      shadow='sm'
      top={0}
      zIndex='sticky'
      {...styles}
    >
      {children}
    </Layout.Container>
  );
};

export default HeaderLayout;
